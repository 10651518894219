import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Experience from '../components/Experience'
import Education from '../components/Education'

export default function About() {
  return (
    <Layout>
      <SEO title="About" />
      <article className="max-w-4xl mx-4 xl:m-auto">
        <h1 className="text-4xl md:text-6xl font-bold tracking-tight sm:my-20 text-black dark:text-white">
          About
        </h1>
        <div className="space-y-16 md:space-y-24 text-black dark:text-white">
          {/*<section className="mt-12 sm:mt-24">
            <div className="w-full md:w-1/2 mx-auto">
              <StaticImage
                src="../images/liip.jpeg"
                className="rounded-full"
                alt="Hey, that's me!"
                title="Hey, that's me!"
                placeholder="blurred"
              />
            </div>
          </section>*/}
          <section>
            <div className="dark:text-white prose-xl mx-auto dark:prose-light">
              {/* <p>
                👋 Hey, I'm <strong>Marius</strong>. I'm a <strong>Frontend Developer</strong>, living in <strong>Switzerland </strong>
                and currently working at <strong>Liip AG</strong>.
              </p> */}
              <p>
                As a developer with over a <strong>decade</strong> of
                experience, I have worked on diverse projects in different{' '}
                <strong>agency</strong> settings. Alongside my professional
                work, I like to keep up with the latest tools and trends in UX
                design through <strong>side projects</strong> during my free
                time.
              </p>
              <p>
                When I'm not coding, I love to{' '}
                <strong>stay active by going for runs</strong> or{' '}
                <strong>whipping up some tasty dishes in the kitchen</strong>. I
                also have a passion for{' '}
                <strong>traveling and exploring new cultures</strong>, which
                always inspires me to think creatively and keep an open mind.
              </p>
            </div>
          </section>
          <section>
            <Experience />
          </section>
          <section>
            <Education />
          </section>
        </div>
      </article>
    </Layout>
  )
}
