import React from 'react'

export default function Tags({ tags }) {
  if (tags) {
    return (
      <div className="flex flex-row flex-wrap">
        {tags.split(', ').map((tag) => (
          <span
            key={tag}
            className="inline-flex items-center px-3 py-0.5 mr-4 mb-3 rounded-full text-sm font-medium bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-100"
          >
            {tag}
          </span>
        ))}
      </div>
    )
  }
  return ''
}
