import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

const Education = () => (
  <StaticQuery
    query={graphql`
      query {
        allMdx(
          filter: { fileAbsolutePath: { regex: "/education/" } }
          sort: { fields: [frontmatter___endDate], order: DESC }
        ) {
          edges {
            node {
              id
              frontmatter {
                startDate(formatString: "YYYY")
                endDate(formatString: "YYYY")
                degree
                school
              }
              body
            }
          }
        }
      }
    `}
    render={(data) => {
      return (
        <div>
          <h2 className="text-3xl md:text-4xl font-bold tracking-tight mb-10">
            👨‍🎓 Education
          </h2>
          {data.allMdx.edges.map(({ node }) => (
            <div className="mb-16" key={node.id}>
              {/* <h4 className="md:text-lg font-medium">
                {node.frontmatter.startDate !== node.frontmatter.endDate
                  ? `${node.frontmatter.startDate} – ${node.frontmatter.endDate}`
                  : node.frontmatter.endDate}
              </h4> */}
              <div>
                <h3 className="text-xl md:text-2xl font-bold mb-4 tracking-tight">
                  {node.frontmatter.degree}
                </h3>
                <p className="text-md md:text-lg">{node.frontmatter.school}</p>
              </div>
            </div>
          ))}
        </div>
      )
    }}
  />
)

export default Education
