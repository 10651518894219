import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import Tags from './Tags'

const Employer = (props) =>
  props.frontmatter.url ? (
    <span>
      <span className="mx-2">@</span>
      <a
        href={props.frontmatter.url}
        target="_blank"
        rel="noopener noreferrer"
        className="text"
      >
        {props.frontmatter.employer}
      </a>
    </span>
  ) : (
    props.frontmatter.employer
  )

const timeSpan = (startDate, endDate) => {
  if (endDate) {
    if (endDate > startDate) {
      return `${startDate} - ${endDate}`
    }
    return startDate
  } else {
    return `${startDate} - now`
  }
}

const Experience = () => (
  <StaticQuery
    query={graphql`
      query {
        allMdx(
          filter: {
            fileAbsolutePath: { regex: "/experience/" }
            frontmatter: { hidden: { ne: true } }
          }
          sort: { fields: [frontmatter___startDate], order: DESC }
        ) {
          edges {
            node {
              id
              frontmatter {
                url
                startDate(formatString: "YYYY")
                endDate(formatString: "YYYY")
                role
                employer
                location
                tags
              }
              body
            }
          }
        }
      }
    `}
    render={(data) => {
      return (
        <div>
          <h2 className="text-3xl md:text-4xl font-bold tracking-tight mb-10">
            💻 Experience
          </h2>
          {data.allMdx.edges.map(({ node }) => (
            <div className="mb-16" key={node.id}>
              <p className="md:text-lg font-medium">
                {timeSpan(node.frontmatter.startDate, node.frontmatter.endDate)}
              </p>
              <h4 className="text-xl md:text-2xl font-bold mb-4 tracking-tight">
                {node.frontmatter.role}
                {node.frontmatter.employer && (
                  <Employer frontmatter={node.frontmatter} />
                )}
              </h4>
              <div className="mb-6 sm:mb-4 prose text-gray-600 dark:text-gray-300">
                <MDXRenderer>{node.body}</MDXRenderer>
              </div>
              <Tags tags={node.frontmatter.tags} />
            </div>
          ))}
        </div>
      )
    }}
  />
)

export default Experience
